
.student-contain {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;

    .left-box {
        width: 100%;
        background-color: #ffffff;

        .class-box {
            padding: 20px 70px;
            position: relative;

            ::v-deep .el-button {
                border-color: #D2D2D2;

                &:hover {
                    color: #ffffff;
                    background-color: #564CED;
                }

                &.active {
                    color: #ffffff;
                    background-color: #564CED;
                }

            }

            .banner-swiper-btn-prev {
                position: absolute;
                left: 24px;
                top: 32px;
                color: #BDBDBD;
                cursor: pointer;

                &:focus {
                    outline: none;
                }

                i {
                    font-weight: bold;
                    font-size: 18px;
                }
            }

            .banner-swiper-btn-next {
                position: absolute;
                right: 24px;
                top: 32px;
                color: #BDBDBD;
                cursor: pointer;

                &:focus {
                    outline: none;
                }

                i {
                    font-weight: bold;
                    font-size: 18px;
                }
            }
        }

        ::v-deep .siwper-item {
            width: unset;
            margin-right: 20px;
        }
    }

    .right-box {
        width: 100%;
        margin-top: 28px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;

        .college-table {
            flex: 1;
            height: 1%;
            width: 100%;

            .icon-background {
                img {
                    width: 34px;
                    height: 32px;
                }
            }


            .account-number {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 45px;

                img {
                    height: 100%;
                    width: 45px;
                    border-radius: 50%;
                }

                .scope-title {
                    width: 260px;
                    margin-left: 10px;
                    text-align: left;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .icon-box {
                display: flex;
                justify-content: center;

                .icon-content {
                    width: 40px;
                    height: 40px;
                    background: #FF6A6A;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    i {
                        font-size: 20px;
                        color: #ffffff;
                    }
                }
            }
        }

        .circle-page {
            padding: 35px;
        }
    }
}
